import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  // connect() {
  // }

  // disconnect() {
  // }

  submit(evt: Event) {
    evt.preventDefault();
    const link = this.element as HTMLAnchorElement;
    const params = [
      ["不具合のあったURL", window.location.href],
      ["ご利用環境", navigator.userAgent],
    ]
      .map(
        ([k, v]: string[]) =>
          `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
      )
      .join("&");

    const url = link.href + "?" + params;

    window.open(url, "_blank");
  }
}
