import { Modal } from "bootstrap5";

export function show(element: Element) {
  const modal = Modal.getOrCreateInstance(element);
  modal.show();
}

export function hide(element: Element) {
  const modal = Modal.getInstance(element);
  modal?.hide();
}

export function dispose(element: Element) {
  Modal.getInstance(element)?.dispose();
  element.parentElement?.removeChild(element);
}

export function onHidden(
  element: Element,
  listener: (element?: Element) => void
) {
  element.addEventListener("hidden.bs.modal", function callback() {
    listener(element);
    element.removeEventListener("hidden.bs.modal", callback);
  });
}
