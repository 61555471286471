import { Controller } from "@hotwired/stimulus";
import { onBeforeVisit, offBeforeVisit } from "@/vendor/turbo";

export default class extends Controller<HTMLFormElement> {
  static values = {
    dirty: Boolean,
  };

  declare dirtyValue: boolean;
  
  private submitting: boolean;

  private onChange = (evt: Event) => {
    const target = evt.target;
    if (isInput(target)) {
      this.dirtyValue = true;
    }
  };

  private onBeforeUnoad = (evt: Event) => {
    if (this.dirtyValue && !this.submitting) {
      evt.preventDefault();
      evt.returnValue = false;
    }
  };

  private onBeforeVisit = (evt: Event) => {
    if (this.dirtyValue && !this.submitting && !confirm("行った変更が保存されない可能性があります。")) {
      evt.preventDefault();
    }
  };

  private onSubmit = () => {
    this.submitting = true;
  }

  connect() {
    console.info("connected", this.dirtyValue)
    this.element.addEventListener("submit", this.onSubmit);
    this.element.addEventListener("change", this.onChange, { passive: false });
    window.addEventListener("beforeunload", this.onBeforeUnoad, { passive: false });
    onBeforeVisit(this.onBeforeVisit, document, false);
  }

  disconnect(): void {
    console.info("disconnected", this.dirtyValue)
    this.element.removeEventListener("submit", this.onSubmit);
    this.element.removeEventListener("change", this.onChange);
    window.removeEventListener("beforeunload", this.onBeforeUnoad);
    offBeforeVisit(this.onBeforeVisit, document);
  }
}

function isInput(
  target: EventTarget
): target is HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement {
  return (
    target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement ||
    target instanceof HTMLSelectElement
  );
}
