import { Controller } from "stimulus";

import PerfectScrollbar from "perfect-scrollbar";

import { supportsPseudo } from "../utils/supportsPseudo";
import { addScrollListener } from "./addScrollListener";

const isWindows = navigator.platform.indexOf("Win") > -1;
const supportsWebkitScrollbar = supportsPseudo("::-webkit-scrollbar");

/*
 * perfect-scrollbar を追加するコントローラー
 */
export default class extends Controller {
  private ondisconnect: (() => void)[];

  connect() {
    this.ondisconnect = [];

    const ps = this.initPerfectScrollbar();
    this.ondisconnect.push(() => ps.destroy());
  }

  disconnect() {
    this.ondisconnect.forEach((callback) => callback.call(null));
    this.ondisconnect.length = 0;
  }

  private initPerfectScrollbar() {
    if (isWindows && this.element instanceof HTMLElement) {
      document.scrollingElement.classList.add("perfect-scrollbar-on");

      if (supportsWebkitScrollbar) {
        document.scrollingElement.classList.add("webkit-scrollbar-on");
      }

      const container = this.element;

      // if we are on windows OS we activate the perfectScrollbar function
      const ps = new PerfectScrollbar(container);
      // スクロール位置を保存・ブラウザバック時に復元
      const scrollListener = addScrollListener(container);
      this.ondisconnect.push(scrollListener.dispose);

      const observer = new MutationObserver((list) => {
        if (
          list.some((e) => !(e.target as HTMLElement).className.match(/ps__/))
        ) {
          ps.update();
        }
      });

      observer.observe(this.element, {
        attributes: true,
        attributeFilter: ["style"],
        childList: true,
        subtree: true,
      });

      return ps;
    }
  }
}
