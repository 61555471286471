import { Controller } from "@hotwired/stimulus";

import DialogPortalController from "./dialog_portal_controller";

import { hide } from "../vendor/dialog";

export default class extends Controller {
  static outlets = ["dialog-portal"];

  declare hasDialogPortalOutlet: boolean;
  declare dialogPortalOutlet: DialogPortalController;

  show() {
    if (this.hasDialogPortalOutlet) {
      this.dialogPortalOutlet.show();
    }
  }

  close() {
    if (this.hasDialogPortalOutlet) {
      this.dialogPortalOutlet.close();
    } else {
      const element = this.element.closest(".modal");
      if (element) {
        hide(element);
      }
    }
  }
}
