import { Controller } from "@hotwired/stimulus";

import autosize from "autosize";
import { querySelectorAll, requestAnimationFrame } from "dom-helpers";

export default class extends Controller {
  private dispose: () => void;

  connect(): void {
    if (this.element instanceof HTMLTextAreaElement) {
      const elements = autosize(this.element);
      this.dispose = () => autosize.destroy(elements);
      requestAnimationFrame(() => autosize.update(elements));
    } else if (this.element instanceof HTMLElement) {
      const elements = autosize(querySelectorAll(this.element, "textarea"));
      this.dispose = () => autosize.destroy(elements);
      requestAnimationFrame(() => autosize.update(elements));
    }
  }

  disconnect(): void {
    this.dispose?.call(null);
    this.dispose = null;
  }
}
