import $ from 'jquery'

// data-href がついている行はリンクにする
$(document).on('click', '[data-href]', function(e: Event) {
  if (!(e.target instanceof HTMLElement)) return

  const target = e.target
  if (target.tagName == 'A' || target.tagName == 'BUTTON') {
    // a, button なら何もしない
    return
  }

  const href = $(this).data('href')
  const a = document.createElement('a')
  a.href = href
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
})
