import { Controller } from "@hotwired/stimulus";

class ControllerBase extends Controller {
  readonly countTargets: HTMLElement[];
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ["count"];

  connect() {
    this.count();
  }

  count() {
    this.countTargets.forEach((e) => {
      const query = e.dataset.countQuery;
      if (query) {
        const count = this.element.querySelectorAll(query).length;
        e.textContent = "" + count;
      }
    });
  }
}
