import { Controller } from "@hotwired/stimulus";

import { uniqueId } from "lodash";

export enum EventName {
  AFTER_INSERT = "nested-form:after-insert",
  AFTER_REMOVE = "nested-form:after-remove",
}

const DEFAULT_TEMPLATE_INDEX = "TEMPLATE_INDEX";

const INSERTED_CLASS = "dynamic";
const REMOVED_CLASS = "destroyed";

export default class extends Controller {
  static targets = ["insertionPoint"];
  static values = { templateIndex: String };

  declare readonly insertionPointTarget!: HTMLElement;
  declare templateIndexValue: string;

  add(event: Event): void {
    event.preventDefault();
    this.append();
  }

  remove(event: Event): void {
    event.preventDefault();
    if (event.target instanceof Element) {
      const target = event.target.closest(".nested-fields") as HTMLElement;

      Array.from(
        target.querySelectorAll("input[name], select[name], textarea[name]"),
        (field: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
          if (field.name.indexOf("[id]") >= 0) {
            return;
          }

          if (field.name.indexOf("[_destroy]") >= 0) {
            field.value = "1";
            return;
          }

          field.disabled = true;
          this.emitChange(field);
        }
      );

      // data-nested-form-remove 属性があれば disabled にする
      Array.from(
        target.querySelectorAll("[data-nested-form-remove]"),
        (e: Element) => {
          e.setAttribute("disabled", "disabled");
          this.emitChange(e);
        }
      );

      target.classList.add(REMOVED_CLASS);
      target.style.display = "none";

      if (target.classList.contains(INSERTED_CLASS) && target.parentElement) {
        target.parentElement.removeChild(target);
      }
      this.emitRemoved(target);
    }
  }

  pasteAdd(event: Event): void {
    if (!(event instanceof ClipboardEvent) || event.type !== "paste") return;

    const pasteData = event.clipboardData.getData("text")
    const dataItems = pasteData.split(/[\t\n]+/).map(_ => _.trim()).filter(_ => !!_)
    // 2 つ以上ないときは通常のペースト
    if (dataItems.length <= 1) return;

    event.preventDefault();

    const inputElement = event.target as (HTMLInputElement | HTMLTextAreaElement);
    const cursorPos = inputElement.selectionStart;
    const originalText = inputElement.value;

    inputElement.value = originalText.slice(0, cursorPos) + dataItems[0] + originalText.slice(cursorPos);

    dataItems.slice(1).forEach(item => {
      const inserted = this.append();

      Array.from(inserted.querySelectorAll("input[type='text'], textarea")).some(newInput => {
        if (!newInput.readonly && !newInput.disabled) {
          newInput.value = item;
          return true;
        }
        return false;
      })
    })
  }

  private emitChange(element: Element) {
    element.dispatchEvent(new Event("input", { bubbles: true }));
    element.dispatchEvent(new Event("change", { bubbles: true }));
  }

  private emitInserted(element: Element, params: string) {
    this.element.dispatchEvent(
      new CustomEvent(EventName.AFTER_INSERT, {
        bubbles: true,
        detail: [element, params],
      })
    );
  }

  private emitRemoved(element: Element) {
    this.element.dispatchEvent(
      new CustomEvent(EventName.AFTER_REMOVE, {
        bubbles: true,
        detail: [element],
      })
    );
  }

  private get $templateIndex(): string {
    return this.templateIndexValue || DEFAULT_TEMPLATE_INDEX;
  }

  private append() {
    const content = this.insertionPointTarget.dataset.content.replace(
      new RegExp(this.$templateIndex, "g"),
      uniqueId("new_record_")
    );
    this.insertionPointTarget.insertAdjacentHTML("beforebegin", content);
    const inserted = this.insertionPointTarget.previousElementSibling;
    if (inserted) {
      inserted.classList.add(INSERTED_CLASS);
      let params: string;
      if (event.target instanceof HTMLElement) {
        params = event.target.dataset.nestedFormParams;
      }
      this.emitInserted(inserted, params);

      const focus = inserted.querySelector("[data-nested-form-focus]");
      if (focus instanceof HTMLElement) {
        focus.focus();
      }
    }
    return inserted;
  }
}
