import debounce from "lodash/debounce";

// スクロール位置を保存・ブラウザバック時に復元
export function addScrollListener(container: HTMLElement) {
  if (container.dataset.scrollPosition) {
    container.scrollTo(JSON.parse(container.dataset.scrollPosition));
  }
  const scrollListener = debounce(() => {
    container.dataset.scrollPosition = JSON.stringify({
      top: container.scrollTop,
      left: container.scrollLeft,
    });
  }, 150);
  container.addEventListener("ps-scroll-x", scrollListener, {
    passive: true,
  });
  container.addEventListener("ps-scroll-y", scrollListener, {
    passive: true,
  });
  container.addEventListener("scroll", scrollListener, {
    passive: true,
  });
  return {
    dispose: () => {
      container.removeEventListener("ps-scroll-x", scrollListener);
      container.removeEventListener("ps-scroll-y", scrollListener);
      container.removeEventListener("scroll", scrollListener);
    },
  };
}
