import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source"];
  static values = {
    success: String,
    error: String,
  };

  declare sourceTarget: HTMLElement;
  declare successValue: string;
  declare errorValue: string;

  copy(): void {
    if (this.select() && document.execCommand("copy")) {
      alert(this.successValue);
    } else {
      alert(this.errorValue);
    }
  }

  private select(): boolean {
    if (
      this.sourceTarget instanceof HTMLInputElement ||
      this.sourceTarget instanceof HTMLTextAreaElement
    ) {
      this.sourceTarget.select();
      return true;
    } else if (window.getSelection) {
      window.getSelection().selectAllChildren(this.sourceTarget);
      return true;
    }
    return false;
  }
}
