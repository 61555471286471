import { Controller } from "stimulus";

import { TempusDominus } from "@eonasdan/tempus-dominus";

export default class extends Controller<HTMLElement> {
  static values = {
    time: Boolean,
  };

  declare timeValue: boolean;

  dispose: () => void;

  connect(): void {
    const picker = new TempusDominus(this.element, {
      useCurrent: false,
      localization: {
        locale: "ja-JP",
        format: this.timeValue ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd",
        today: "今日に移動",
        clear: "入力を破棄",
        close: "閉じる",
        selectMonth: "月を選択",
        previousMonth: "前の月",
        nextMonth: "次の月",
        selectYear: "年を選択",
        previousYear: "前の年",
        nextYear: "次の年",
        selectDecade: "10年単位で選択",
        previousDecade: "前の10年",
        nextDecade: "次の10年",
        previousCentury: "前の100年",
        nextCentury: "次の100年",
        selectDate: "日付を選択",
        selectTime: "時刻を選択",
        decrementHour: "時を減らす",
        decrementMinute: "分を減らす",
        decrementSecond: "秒を減らす",
        incrementHour: "時を増やす",
        incrementMinute: "分を増やす",
        incrementSecond: "秒を増やす",
        dayViewHeaderFormat: {
          year: "numeric",
          month: "long",
        },
        hourCycle: "h23",
      },
      display: {
        sideBySide: this.timeValue,
        components: {
          decades: false,
          clock: this.timeValue,
          hours: this.timeValue,
          minutes: this.timeValue,
          seconds: false,
        },
        buttons: {
          today: true,
          close: true,
          clear: true,
        },
        icons: {
          time: "fa fa-clock",
          date: "fa fa-calendar",
          up: "fa fa-chevron-up",
          down: "fa fa-chevron-down",
          previous: "fa fa-chevron-left",
          next: "fa fa-chevron-right",
          today: "fa fa-history",
          clear: "fa fa-trash",
          close: "fa fa-remove",
        },
      },
    });

    picker.subscribe("change.td", () => {
      this.element.dispatchEvent(new Event("change", { bubbles: true }));
    });

    this.dispose = () => picker.dispose();
  }

  disconnect(): void {
    if (this.dispose) {
      this.dispose();
      this.dispose = null;
    }
  }
}
