import "@stimulus/polyfills";
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

import { registerAttachmentUploadControllers } from "@pcegg/attachment_upload";

const application = Application.start();

const context = require.context("controllers", true, /_controller\.(js|ts)$/);

// view_component で定義しているコントローラを読み込み
const componentsContext = require.context(
  "../../components",
  true,
  /_controller\.(js|ts)$/
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(componentsContext)
  )
);

registerAttachmentUploadControllers(application);
