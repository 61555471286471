import izitoast, { IziToastSettings } from 'izitoast'
import 'izitoast/dist/css/iziToast.css'

function decodeQueryParam(p: string) {
  return decodeURIComponent(p.replace(/\+/g, ' '));
}

document.addEventListener(
  'ajax:complete',
  (e: CustomEvent<[XMLHttpRequest, string]>) => {
    const [xhr] = e.detail

    const flash = xhr.getResponseHeader('X-Flash-Messages')
    if (flash) {
      const { notice = '', error = '', alert = '' } = JSON.parse(
        flash
      ) as Record<string, string>
      const options: IziToastSettings = {
        position: 'topCenter',
        timeout: 3000,
      }

      if (notice) {
        izitoast.success({
          ...options,
          message: decodeQueryParam(notice),
        })
      }
      if (error) {
        izitoast.error({
          ...options,
          message: decodeQueryParam(error),
        })
      }
      if (alert) {
        izitoast.warning({
          ...options,
          message: decodeQueryParam(alert),
        })
      }
    }
  }
)
