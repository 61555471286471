import { Controller } from "@hotwired/stimulus";
import type { FrameElement } from "@hotwired/turbo";

export default class extends Controller<FrameElement> {
  static values = {
    src: String,
    target: String,
  };

  declare srcValue: string;
  declare targetValue: string;

  connect() {
    document.addEventListener("change", this.onChange);
  }

  disconnect(): void {
    document.removeEventListener("change", this.onChange);
  }

  private onChange = (event: Event) => {
    if (
      event.target instanceof Element &&
      event.target.matches(this.targetValue)
    ) {
      const select = event.target as HTMLSelectElement;
      const src = this.srcValue;
      const param = new URLSearchParams();
      param.set(select.name, select.value);
      this.element.src = `${src}?${param}`;
    }
  };
}
