import { Controller } from "@hotwired/stimulus";

import $ from "jquery";
import "select2";
import "select2/dist/css/select2.css";

export default class extends Controller<HTMLSelectElement> {
  connect(): void {
    $(this.element).select2();
    window.addEventListener("resize", this.onResize);
  }

  disconnect(): void {
    window.removeEventListener("resize", this.onResize);
  }

  private onResize = () => {
    $(this.element).select2({
      width: "element",
    });
  };
}
