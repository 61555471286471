import { Controller } from "stimulus";
import { uniqueId } from "lodash";

import { discardPopover, initPopover } from "@/vendor/bootstrap5";

export default class extends Controller<HTMLElement> {
  connect(): void {
    if (!this.element.id) {
      // morphdom で別ノード扱いにして強制的に更新する
      this.element.id = uniqueId("bs-toggle");
    }
    initPopover(this.element);
  }

  disconnect(): void {
    discardPopover(this.element);
  }
}
