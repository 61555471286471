import { Controller } from "stimulus";

import { onLoad, offLoad } from "../vendor/turbo";

/*
 * paper-dashboard のふるまいを body タグに追加するコントローラー。
 * paper-dashboard.js を置き換える。
 */
export default class extends Controller {
  static targets = ["sidebarToggle", "bodyClick"];

  declare hasBodyClickTarget: boolean;
  declare bodyClickTargets: Element[];

  declare hasSidebarToggleTarget: boolean;
  declare sidebarToggleTarget: Element;

  connect() {
    this.initSidebar();

    // turbolinks で移動するときはサイドバーを閉じる
    onLoad(this.closeSidebar);

    // sidebar 開閉ボタンを controller の制御下に入れる
    Array.from(
      this.element.querySelectorAll(".main-panel .navbar .navbar-toggle"),
      (toggle) => {
        toggle.setAttribute(
          `data-${this.scope.identifier}-target`,
          `sidebarToggle`
        );
        toggle.setAttribute(
          "data-action",
          `click->${this.scope.identifier}#toggleSidebar`
        );
      }
    );

    // perfect-scrollbar を有効にする
    Array.from(
      this.element.querySelectorAll(".sidebar .sidebar-wrapper, .main-panel"),
      (container) =>
        container.setAttribute("data-controller", "perfect-scrollbar")
    );
  }

  disconnect() {
    offLoad(this.closeSidebar);
  }

  initSidebar() {
    if (this.sidebarOpen) {
      this.openSidebar();
    }
  }

  toggleSidebar() {
    if (this.sidebarOpen) {
      this.closeSidebar();
    } else {
      this.openSidebar(580);
    }
  }

  openSidebar(delay = 0) {
    if (!this.hasBodyClickTarget) {
      const bodyClick = document.createElement("div");
      bodyClick.setAttribute("id", "bodyClick");
      bodyClick.setAttribute(
        `data-${this.scope.identifier}-target`,
        "bodyClick"
      );
      bodyClick.setAttribute(
        "data-action",
        `click->${this.scope.identifier}#closeSidebar`
      );
      this.element.appendChild(bodyClick);
    }

    this.sidebarOpen = true;
    if (delay) {
      setTimeout(() => {
        this.sidebarToggled = true;
      }, delay);
    } else {
      this.sidebarToggled = true;
    }
  }

  private closeSidebar = () => {
    this.sidebarOpen = false;
    this.sidebarToggled = false;

    if (this.hasBodyClickTarget) {
      Array.from(this.bodyClickTargets, (bodyClick) => {
        bodyClick.parentElement.removeChild(bodyClick);
      });
    }
  };

  private get sidebarOpen() {
    return this.rootElement.classList.contains("nav-open");
  }

  private set sidebarOpen(value: boolean) {
    if (value) {
      this.rootElement.classList.add("nav-open");
    } else {
      this.rootElement.classList.remove("nav-open");
    }
  }

  private set sidebarToggled(value: boolean) {
    if (this.hasSidebarToggleTarget) {
      if (value) {
        this.sidebarToggleTarget.classList.add("toggled");
      } else {
        this.sidebarToggleTarget.classList.remove("toggled");
      }
    }
  }

  private get rootElement() {
    return this.element.parentElement;
  }
}
