import { Controller } from "@hotwired/stimulus";

import { hide, onHidden, dispose } from "@/vendor/dialog";
import { onSubmitSuccess } from "@/vendor/turbo";

export default class extends Controller<HTMLElement> {
  disposable: (() => void)[] = [];

  connect(): void {
    onHidden(this.element, dispose);

    const unsubscribe = onSubmitSuccess(this.element, (evt) => {
      const {
        detail: { formSubmission },
      } = evt;
      if (formSubmission.result?.success) {
        this.close();
      }
    })
    this.disposable.push(unsubscribe);
  }

  close() {
    hide(this.element);
    this.disposable.forEach((callback) => callback());
    this.disposable.length = 0;
  }
}
