import { Controller } from "@hotwired/stimulus";

import $ from "jquery";

export default class extends Controller {
  show(): void {
    $(this.element).modal("show");
  }

  hide(): void {
    $(this.element).modal("hide");
  }

  dispose(): void {
    $(this.element).modal("dispose").remove();
  }
}
