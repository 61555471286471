import { Controller as B } from "stimulus";
/*!
 * mustache.js - Logic-less {{mustache}} templates with JavaScript
 * http://github.com/janl/mustache.js
 */
var z = Object.prototype.toString, L = Array.isArray || function(e) {
  return z.call(e) === "[object Array]";
};
function R(t) {
  return typeof t == "function";
}
function G(t) {
  return L(t) ? "array" : typeof t;
}
function V(t) {
  return t.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
function M(t, e) {
  return t != null && typeof t == "object" && e in t;
}
function J(t, e) {
  return t != null && typeof t != "object" && t.hasOwnProperty && t.hasOwnProperty(e);
}
var Q = RegExp.prototype.test;
function X(t, e) {
  return Q.call(t, e);
}
var Y = /\S/;
function Z(t) {
  return !X(Y, t);
}
var ee = {
  "&": "&amp;",
  "<": "&lt;",
  ">": "&gt;",
  '"': "&quot;",
  "'": "&#39;",
  "/": "&#x2F;",
  "`": "&#x60;",
  "=": "&#x3D;"
};
function te(t) {
  return String(t).replace(/[&<>"'`=\/]/g, function(r) {
    return ee[r];
  });
}
var re = /\s*/, se = /\s+/, H = /\s*=/, ne = /\s*\}/, ae = /#|\^|\/|>|\{|&|=|!/;
function ie(t, e) {
  if (!t)
    return [];
  var r = !1, s = [], n = [], a = [], i = !1, u = !1, o = "", h = 0;
  function f() {
    if (i && !u)
      for (; a.length; )
        delete n[a.pop()];
    else
      a = [];
    i = !1, u = !1;
  }
  var y, w, N;
  function D(T) {
    if (typeof T == "string" && (T = T.split(se, 2)), !L(T) || T.length !== 2)
      throw new Error("Invalid tags: " + T);
    y = new RegExp(V(T[0]) + "\\s*"), w = new RegExp("\\s*" + V(T[1])), N = new RegExp("\\s*" + V("}" + T[1]));
  }
  D(e || c.tags);
  for (var l = new q(t), b, d, v, P, S, m; !l.eos(); ) {
    if (b = l.pos, v = l.scanUntil(y), v)
      for (var k = 0, K = v.length; k < K; ++k)
        P = v.charAt(k), Z(P) ? (a.push(n.length), o += P) : (u = !0, r = !0, o += " "), n.push(["text", P, b, b + 1]), b += 1, P === `
` && (f(), o = "", h = 0, r = !1);
    if (!l.scan(y))
      break;
    if (i = !0, d = l.scan(ae) || "name", l.scan(re), d === "=" ? (v = l.scanUntil(H), l.scan(H), l.scanUntil(w)) : d === "{" ? (v = l.scanUntil(N), l.scan(ne), l.scanUntil(w), d = "&") : v = l.scanUntil(w), !l.scan(w))
      throw new Error("Unclosed tag at " + l.pos);
    if (d == ">" ? S = [d, v, b, l.pos, o, h, r] : S = [d, v, b, l.pos], h++, n.push(S), d === "#" || d === "^")
      s.push(S);
    else if (d === "/") {
      if (m = s.pop(), !m)
        throw new Error('Unopened section "' + v + '" at ' + b);
      if (m[1] !== v)
        throw new Error('Unclosed section "' + m[1] + '" at ' + b);
    } else
      d === "name" || d === "{" || d === "&" ? u = !0 : d === "=" && D(v);
  }
  if (f(), m = s.pop(), m)
    throw new Error('Unclosed section "' + m[1] + '" at ' + l.pos);
  return oe(ue(n));
}
function ue(t) {
  for (var e = [], r, s, n = 0, a = t.length; n < a; ++n)
    r = t[n], r && (r[0] === "text" && s && s[0] === "text" ? (s[1] += r[1], s[3] = r[3]) : (e.push(r), s = r));
  return e;
}
function oe(t) {
  for (var e = [], r = e, s = [], n, a, i = 0, u = t.length; i < u; ++i)
    switch (n = t[i], n[0]) {
      case "#":
      case "^":
        r.push(n), s.push(n), r = n[4] = [];
        break;
      case "/":
        a = s.pop(), a[5] = n[2], r = s.length > 0 ? s[s.length - 1][4] : e;
        break;
      default:
        r.push(n);
    }
  return e;
}
function q(t) {
  this.string = t, this.tail = t, this.pos = 0;
}
q.prototype.eos = function() {
  return this.tail === "";
};
q.prototype.scan = function(e) {
  var r = this.tail.match(e);
  if (!r || r.index !== 0)
    return "";
  var s = r[0];
  return this.tail = this.tail.substring(s.length), this.pos += s.length, s;
};
q.prototype.scanUntil = function(e) {
  var r = this.tail.search(e), s;
  switch (r) {
    case -1:
      s = this.tail, this.tail = "";
      break;
    case 0:
      s = "";
      break;
    default:
      s = this.tail.substring(0, r), this.tail = this.tail.substring(r);
  }
  return this.pos += s.length, s;
};
function C(t, e) {
  this.view = t, this.cache = { ".": this.view }, this.parent = e;
}
C.prototype.push = function(e) {
  return new C(e, this);
};
C.prototype.lookup = function(e) {
  var r = this.cache, s;
  if (r.hasOwnProperty(e))
    s = r[e];
  else {
    for (var n = this, a, i, u, o = !1; n; ) {
      if (e.indexOf(".") > 0)
        for (a = n.view, i = e.split("."), u = 0; a != null && u < i.length; )
          u === i.length - 1 && (o = M(a, i[u]) || J(a, i[u])), a = a[i[u++]];
      else
        a = n.view[e], o = M(n.view, e);
      if (o) {
        s = a;
        break;
      }
      n = n.parent;
    }
    r[e] = s;
  }
  return R(s) && (s = s.call(this.view)), s;
};
function p() {
  this.templateCache = {
    _cache: {},
    set: function(e, r) {
      this._cache[e] = r;
    },
    get: function(e) {
      return this._cache[e];
    },
    clear: function() {
      this._cache = {};
    }
  };
}
p.prototype.clearCache = function() {
  typeof this.templateCache < "u" && this.templateCache.clear();
};
p.prototype.parse = function(e, r) {
  var s = this.templateCache, n = e + ":" + (r || c.tags).join(":"), a = typeof s < "u", i = a ? s.get(n) : void 0;
  return i == null && (i = ie(e, r), a && s.set(n, i)), i;
};
p.prototype.render = function(e, r, s, n) {
  var a = this.getConfigTags(n), i = this.parse(e, a), u = r instanceof C ? r : new C(r, void 0);
  return this.renderTokens(i, u, s, e, n);
};
p.prototype.renderTokens = function(e, r, s, n, a) {
  for (var i = "", u, o, h, f = 0, y = e.length; f < y; ++f)
    h = void 0, u = e[f], o = u[0], o === "#" ? h = this.renderSection(u, r, s, n, a) : o === "^" ? h = this.renderInverted(u, r, s, n, a) : o === ">" ? h = this.renderPartial(u, r, s, a) : o === "&" ? h = this.unescapedValue(u, r) : o === "name" ? h = this.escapedValue(u, r, a) : o === "text" && (h = this.rawValue(u)), h !== void 0 && (i += h);
  return i;
};
p.prototype.renderSection = function(e, r, s, n, a) {
  var i = this, u = "", o = r.lookup(e[1]);
  function h(w) {
    return i.render(w, r, s, a);
  }
  if (!!o) {
    if (L(o))
      for (var f = 0, y = o.length; f < y; ++f)
        u += this.renderTokens(e[4], r.push(o[f]), s, n, a);
    else if (typeof o == "object" || typeof o == "string" || typeof o == "number")
      u += this.renderTokens(e[4], r.push(o), s, n, a);
    else if (R(o)) {
      if (typeof n != "string")
        throw new Error("Cannot use higher-order sections without the original template");
      o = o.call(r.view, n.slice(e[3], e[5]), h), o != null && (u += o);
    } else
      u += this.renderTokens(e[4], r, s, n, a);
    return u;
  }
};
p.prototype.renderInverted = function(e, r, s, n, a) {
  var i = r.lookup(e[1]);
  if (!i || L(i) && i.length === 0)
    return this.renderTokens(e[4], r, s, n, a);
};
p.prototype.indentPartial = function(e, r, s) {
  for (var n = r.replace(/[^ \t]/g, ""), a = e.split(`
`), i = 0; i < a.length; i++)
    a[i].length && (i > 0 || !s) && (a[i] = n + a[i]);
  return a.join(`
`);
};
p.prototype.renderPartial = function(e, r, s, n) {
  if (!!s) {
    var a = this.getConfigTags(n), i = R(s) ? s(e[1]) : s[e[1]];
    if (i != null) {
      var u = e[6], o = e[5], h = e[4], f = i;
      o == 0 && h && (f = this.indentPartial(i, h, u));
      var y = this.parse(f, a);
      return this.renderTokens(y, r, s, f, n);
    }
  }
};
p.prototype.unescapedValue = function(e, r) {
  var s = r.lookup(e[1]);
  if (s != null)
    return s;
};
p.prototype.escapedValue = function(e, r, s) {
  var n = this.getConfigEscape(s) || c.escape, a = r.lookup(e[1]);
  if (a != null)
    return typeof a == "number" && n === c.escape ? String(a) : n(a);
};
p.prototype.rawValue = function(e) {
  return e[1];
};
p.prototype.getConfigTags = function(e) {
  return L(e) ? e : e && typeof e == "object" ? e.tags : void 0;
};
p.prototype.getConfigEscape = function(e) {
  if (e && typeof e == "object" && !L(e))
    return e.escape;
};
var c = {
  name: "mustache.js",
  version: "4.2.0",
  tags: ["{{", "}}"],
  clearCache: void 0,
  escape: void 0,
  parse: void 0,
  render: void 0,
  Scanner: void 0,
  Context: void 0,
  Writer: void 0,
  set templateCache(t) {
    A.templateCache = t;
  },
  get templateCache() {
    return A.templateCache;
  }
}, A = new p();
c.clearCache = function() {
  return A.clearCache();
};
c.parse = function(e, r) {
  return A.parse(e, r);
};
c.render = function(e, r, s, n) {
  if (typeof e != "string")
    throw new TypeError('Invalid template! Template should be a "string" but "' + G(e) + '" was given as the first argument for mustache#render(template, view, partials)');
  return A.render(e, r, s, n);
};
c.escape = te;
c.Scanner = q;
c.Context = C;
c.Writer = p;
function O(t) {
  return /^image\//.test(t);
}
function F(t) {
  return /^video\//.test(t);
}
function W(t) {
  return /^audio\//.test(t);
}
function he(t) {
  return W(t) ? "audio" : F(t) ? "video" : O(t) ? "image" : "binary";
}
const E = [
  [1024 * 1024 * 1024, "\u30AE\u30AC\u30D0\u30A4\u30C8"],
  [1024 * 1024, "\u30E1\u30AC\u30D0\u30A4\u30C8"],
  [1024, "\u30AD\u30ED\u30D0\u30A4\u30C8"],
  [1, "\u30D0\u30A4\u30C8"]
];
function le(t) {
  let e = E[E.length - 1][1];
  for (let r = 0; r < E.length; r++) {
    const s = E[r][0];
    if (!(t < s)) {
      t = t * 1 / s, e = E[r][1];
      break;
    }
  }
  return Number(t).toLocaleString(void 0, { maximumFractionDigits: 1 }) + e;
}
function ce(t) {
  return !("url" in t);
}
function x(t) {
  return t ? {
    filename: t.name,
    filesize: le(t.size),
    filetype: he(t.type),
    url: ce(t) ? URL.createObjectURL(t) : t.url,
    isAudio: W(t.type),
    isVideo: F(t.type),
    isImage: O(t.type)
  } : {};
}
function g(t) {
  return `attachment-upload--${t}`;
}
const _ = (t) => {
  const e = t.target;
  e instanceof HTMLInputElement && e.closest(".btn").classList.add("active");
}, j = (t) => {
  const e = t.target;
  e instanceof HTMLInputElement && e.closest(".btn").classList.remove("active");
};
function $(t) {
  return t.addEventListener("dragenter", _), t.addEventListener("dragleave", j), () => {
    t.removeEventListener("dragenter", _), t.removeEventListener("dragleave", j);
  };
}
class I extends B {
  connect() {
    this.dispose = $(this.element), this.hasPreviewTemplateTarget && (this.template = this.previewTemplateTarget.innerHTML, c.parse(this.template)), this.sync();
  }
  disconnect() {
    var e;
    (e = this.dispose) == null || e.call(null), this.dispose = null;
  }
  sync() {
    this.required ? this.rootTarget.classList.add(g("required")) : this.rootTarget.classList.remove(g("required")), this.removed ? (this.rootTarget.classList.add(g("removed")), this.inputTarget.disabled = !0, Array.from(this.buttonTargets, (e) => {
      e.classList.add("disabled");
    })) : (this.rootTarget.classList.remove(g("removed")), this.inputTarget.disabled = !1, Array.from(this.buttonTargets, (e) => {
      e.classList.remove("disabled");
    })), this.inputTarget.value || this.cache ? (this.beChanged(), this.updatePreview()) : this.present ? (this.beNotChanged(), this.updatePreview(), this.bePresent()) : (this.beNotChanged(), this.beNotPresent());
  }
  onChange() {
    this.hasCacheTarget && (this.cacheTarget.disabled = !0), this.changed = !!this.inputTarget.value, this.sync();
  }
  cancel() {
    this.inputTarget.value && (this.inputTarget.value = null), this.hasCacheTarget && (this.cacheTarget.disabled = !0), this.changed = !1, this.sync();
  }
  bePresent() {
    this.rootTarget.classList.add(g("present")), this.inputTarget.required = !1;
  }
  beNotPresent() {
    this.rootTarget.classList.remove(g("present")), this.required && (this.inputTarget.required = !0);
  }
  beChanged() {
    this.rootTarget.classList.add(g("changed"));
  }
  beNotChanged() {
    this.rootTarget.classList.remove(g("changed"));
  }
  updatePreview() {
    let e;
    this.inputTarget.files.length ? e = this.inputTarget.files[0] : e = this.cache;
    const r = x(e);
    this.hasPreviewTarget && (this.previewTarget.innerHTML = c.render(this.template, r));
  }
  get required() {
    return this.requiredValue;
  }
  get present() {
    return this.presentValue;
  }
  get changed() {
    return this.changedValue;
  }
  set changed(e) {
    e ? this.changedValue = !0 : this.changedValue = !1;
  }
  get removed() {
    return this.removeTarget.checked;
  }
  get cache() {
    if (this.hasCacheValue)
      return this.cacheValue;
  }
}
I.targets = [
  "root",
  "input",
  "cache",
  "preview",
  "previewTemplate",
  "remove",
  "button"
];
I.values = {
  required: Boolean,
  present: Boolean,
  cache: Object,
  changed: Boolean,
  preview: Boolean
};
class U extends B {
  connect() {
    this.dispose = $(this.element), this.hasFilelistTemplateTarget && (this.template = this.filelistTemplateTarget.innerHTML, c.parse(this.template)), this.hasInputTarget || this.createInput(), this.sync();
  }
  disconnect() {
    var e;
    (e = this.dispose) == null || e.call(null), this.dispose = null;
  }
  sync() {
    this.required ? this.rootTarget.classList.add(g("required")) : this.rootTarget.classList.remove(g("required")), this.hasValue() ? (this.beChanged(), this.updatePreview(), this.bePresent()) : this.changed ? (this.beChanged(), this.updatePreview()) : this.present ? (this.beNotChanged(), this.updatePreview(), this.bePresent()) : (this.beNotChanged(), this.beNotPresent());
  }
  hasValue() {
    return this.hasInputTarget ? Array.from(this.inputTargets).some((e) => !!e.value) : !1;
  }
  onChange(e) {
    e.target.value && (Array.from(this.inputWrapperTargets, (r) => {
      r.style.display = "none";
    }), this.createInput()), this.sync();
  }
  cancel() {
    Array.from(this.inputTargets, (e) => {
      e.value = null;
    }), this.changed = !1, this.sync();
  }
  bePresent() {
    this.rootTarget.classList.add(g("present")), Array.from(this.inputTargets, (e) => e.required = !1);
  }
  beNotPresent() {
    this.rootTarget.classList.remove(g("present")), this.required && Array.from(this.inputTargets, (e) => e.required = !0);
  }
  beChanged() {
    this.rootTarget.classList.add(g("changed"));
  }
  beNotChanged() {
    this.rootTarget.classList.remove(g("changed"));
  }
  updatePreview() {
    const e = [];
    Array.from(this.inputTargets, (s) => {
      Array.from(s.files, (n) => {
        e.push(n);
      });
    });
    const r = {
      files: e.map(x)
    };
    if (this.hasFilelistTarget) {
      const s = c.render(this.template, r);
      this.filelistTarget.innerHTML = s;
    }
  }
  createInput() {
    const e = this.inputTemplateTarget.innerHTML;
    this.inputTemplateTarget.insertAdjacentHTML("beforebegin", e);
  }
  get required() {
    return this.requiredValue;
  }
  get present() {
    return this.presentValue;
  }
  set present(e) {
    e ? this.presentValue = !0 : this.presentValue = null;
  }
  get changed() {
    return this.changedValue;
  }
  set changed(e) {
    e ? this.changedValue = !0 : this.changedValue = !1;
  }
}
U.targets = [
  "root",
  "input",
  "inputWrapper",
  "inputTemplate",
  "filelist",
  "filelistTemplate"
];
U.values = {
  changed: Boolean,
  required: Boolean,
  present: Boolean,
  preview: Boolean
};
function fe(t) {
  t.register("attachment-upload--one", I), t.register("attachment-upload--many", U);
}
export {
  U as ManyController,
  I as OneController,
  fe as registerAttachmentUploadControllers
};
