import { Controller } from "@hotwired/stimulus";

/**
 * input, textarea に設定して半角全角変換、前後の空白除去を行う。
 */
export default class extends Controller {
  static values = {
    halve: Boolean,
    strip: Boolean,
  };

  declare halveValue: boolean;
  declare stripValue: boolean;

  private onBlur = () => this.normalize();

  connect() {
    this.element.addEventListener("blur", this.onBlur);
  }

  disconnect() {
    this.element.removeEventListener("blur", this.onBlur);
  }

  normalize() {
    const input = this.input;
    let value = input.value;
    if (this.halveValue) {
      value = halve(value);
    }
    if (this.stripValue) {
      value = strip(value);
    }
    if (input.value !== value) {
      input.value = value;
      input.dispatchEvent(new Event("input", { bubbles: true }));
      input.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }

  get input() {
    return this.element as HTMLInputElement | HTMLTextAreaElement;
  }
}

function halve(str: string) {
  return str?.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (c) => {
    return String.fromCharCode(c.charCodeAt(0) - 0xfee0);
  });
}

function strip(str: string) {
  return str?.trim();
}
