import { Controller } from "@hotwired/stimulus";

class ControllerBase extends Controller {
  conditionTarget: HTMLSelectElement;
  toTarget: HTMLSelectElement;
  inputTargets: HTMLInputElement[];
}

export default class extends (Controller as typeof ControllerBase) {
  static targets = ["condition", "to", "input"];

  /**
   * conditionTarget の value が data-current-value の値と同じラジオボタンを選択した状態にする
   */
  change() {
    const condition = this.conditionTarget.value;
    const to = this.toTarget.value;
    const checker = new Checker();
    this.inputTargets.forEach((input) => {
      if (input.disabled || input.readOnly) {
        return;
      }
      if (condition && input.dataset.currentValue != condition) {
        return;
      }
      if (input.value == to) {
        checker.check(input);
      }
    });
    checker.dispatchEvent();
  }

  reset() {
    const checker = new Checker();
    this.inputTargets.forEach((input) => {
      if (input.dataset.currentValue == input.value && !input.checked) {
        checker.check(input);
      }
    });
    checker.dispatchEvent();
  }
}

class Checker {
  inputs: HTMLInputElement[] = [];
  check(input: HTMLInputElement) {
    if (!input.checked) {
      input.checked = true;
      this.inputs.push(input);
    }
  }

  dispatchEvent() {
    this.inputs.forEach((input) => {
      input.dispatchEvent(new Event("change", { bubbles: true }));
    });
  }
}
