import { Controller } from "@hotwired/stimulus";

import { show, hide, onHidden } from "@/vendor/dialog";

export default class extends Controller<HTMLTemplateElement> {
  private _node: Element | undefined;

  disconnect(): void {
    const node = this._node;
    if (node) {
      hide(node);
    }
  }

  show() {
    const node = this.findOrCreateNode();
    if (node) {
      show(node);
    }
  }

  close() {
    const node = this._node;
    if (node) {
      hide(node);
    }
  }

  private findOrCreateNode(): Element | undefined {
    if (this._node) {
      return this._node;
    }

    const node = this.element.content.firstElementChild?.cloneNode(true) as
      | Element
      | undefined;
    if (node) {
      document.body.appendChild(node);
      onHidden(node, () => (this._node = undefined));
    }

    return (this._node = node);
  }
}
