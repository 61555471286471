/**
 * カンマ区切りで値を表示する
 *
 * value 数値
 * formatStyle percent, currency, decimal
 */
export class IntlNumber extends HTMLElement {
  // attributeChangedCallback を有効にする
  static observedAttributes = [
    "value",
    "format-style",
    "maximum-fraction-digits",
    "maximum-significant-digits",
    "minimum-fraction-digits",
    "minimum-integer-digits",
    "minimum-significant-digits",
  ];

  private view: HTMLElement;

  constructor() {
    super();

    const shadow = this.attachShadow({ mode: "open" });

    const view = document.createElement("span");
    this.view = view;
    shadow.appendChild(view);

    this.onChange();
  }

  attributeChangedCallback(): void {
    this.onChange();
  }

  private onChange(): void {
    const value = this.value;
    if (value === null || value === "") {
      this.view.textContent = "";
      return;
    }

    const formatOptions = this.getFormatOptions();
    const format = new Intl.NumberFormat(undefined, formatOptions);
    const number = parseFloat(this.value);
    this.view.textContent = isNaN(number) ? "" : format.format(number);
  }

  get value(): string | null {
    return this.getAttribute("value");
  }

  set value(value: string) {
    this.setAttribute("value", value);
  }

  get disabled(): boolean {
    return this.hasAttribute("disabled");
  }

  set disabled(disabled: boolean) {
    if (disabled) {
      this.setAttribute("disabled", "disabled");
    } else {
      this.removeAttribute("disabled");
    }
  }

  private getFormatOptions(): Intl.NumberFormatOptions {
    const options: Intl.NumberFormatOptions = {};
    if (this.hasAttribute("format-style")) {
      options.style = this.getAttribute("format-style");
    }
    if (this.hasAttribute("maximum-fraction-digits")) {
      options.maximumFractionDigits = parseInt(
        this.getAttribute("maximum-fraction-digits")
      );
    }
    if (this.hasAttribute("maximum-significant-digits")) {
      options.maximumSignificantDigits = parseInt(
        this.getAttribute("maximum-significant-digits")
      );
    }
    if (this.hasAttribute("minimum-fraction-digits")) {
      options.minimumFractionDigits = parseInt(
        this.getAttribute("minimum-fraction-digits")
      );
    }
    if (this.hasAttribute("minimum-integer-digits")) {
      options.minimumIntegerDigits = parseInt(
        this.getAttribute("minimum-integer-digits")
      );
    }
    if (this.hasAttribute("minimum-significant-digits")) {
      options.minimumSignificantDigits = parseInt(
        this.getAttribute("minimum-significant-digits")
      );
    }
    return options;
  }
}

customElements.define("intl-number", IntlNumber);
