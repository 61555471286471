import { Controller } from "@hotwired/stimulus";

import writeQR from "@paulmillr/qr";

export default class extends Controller<HTMLElement> {
  static values = {
    text: String,
  };

  declare textValue: string;

  connect(): void {
    const gifBytes = writeQR(this.textValue, "gif", { scale: 4, border: 4 });
    // バイトデータからBlobを作成
    const blob = new Blob([new Uint8Array(gifBytes)], { type: "image/gif" });

    // BlobからDataURLを作成
    const reader = new FileReader();
    reader.onload = (event) => {
      const dataUrl = event.target.result as string;

      // 画像を読み込み
      const img = document.createElement("img");
      img.src = dataUrl;
      this.element.innerHTML = "";
      this.element.appendChild(img);
    };
    reader.readAsDataURL(blob);
  }
}
