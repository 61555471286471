import { Tooltip, Popover } from "bootstrap5";

export const initPopover = (element: HTMLElement) => {
  const Popup = popupFor(element.dataset.bsToggle);
  Popup?.getOrCreateInstance(element);
};

export const discardPopover = (element: HTMLElement) => {
  const Popup = popupFor(element.dataset.bsToggle);
  const instance = Popup?.getInstance(element);
  if (instance) {
    const hiddenEvent = `hidden.bs.${Popup.NAME}`;
    element.addEventListener(hiddenEvent, function dispose() {
      instance.dispose();
      element.removeEventListener(hiddenEvent, dispose);
    });
    instance.hide();
  }
};

export const initPopovers = (element: Element | Document = document) => {
  foreachPopupToggle(element, initPopover);
};

export const discardPopovers = (element: Element | Document = document) => {
  foreachPopupToggle(element, discardPopover);
};

function foreachPopupToggle(
  element: Element | Document,
  f: (e: HTMLElement) => void
) {
  element
    .querySelectorAll('[data-bs-toggle="tooltip"], [data-bs-toggle="popover"]')
    .forEach(f);
}

function popupFor(type: string) {
  if (type == "tooltip") {
    return Tooltip;
  } else if (type == "popover") {
    return Popover;
  }
}
