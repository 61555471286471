import { Controller } from "@hotwired/stimulus";
import { Sortable, MultiDrag } from "sortablejs";
import type { SortableOptions } from "sortablejs";

Sortable.mount(new MultiDrag());

export default class extends Controller {
  static targets = ["root"];
  static values = { options: Object };

  declare readonly hasRootTarget: boolean;
  declare readonly rootTarget: HTMLElement;
  declare readonly optionsValue: SortableOptions;

  private sortable: Sortable;

  connect(): void {
    this.sortable = new Sortable(this.root, {
      multiDrag: true,
      selectedClass: "sortable-selected",
      ...this.optionsValue,
      onUpdate: this.onUpdate,
    });

    // turbo でページ遷移するときに破棄する
    document.addEventListener("turbo:before-cache", this.onBeforeCache);

    this.onUpdate();
  }

  // sortable がネストしているときに外側をソートすると、そのたび内側は disconnect される。
  // おそらくその副作用で外側の onXXX イベントが発生しなくなるため、disconnect では destroy しない。
  disconnect(): void {
    document.removeEventListener("turbo:before-cache", this.onBeforeCache);
  }

  update(event: Event): void {
    if (event.target === this.element) {
      this.onUpdate();
    }
  }

  destroy(): void {
    if (this.sortable) {
      this.sortable.destroy();
      this.sortable = null;
    }
  }

  private onUpdate = () => {
    const ids = this.sortable.toArray();
    ids
      .map((id, idx) => {
        const input = this.element.querySelector(`[data-sortable="${id}"]`);
        if (input instanceof HTMLInputElement) {
          if (!input.dataset.sortableOriginalValue) {
            input.dataset.sortableOriginalValue = input.value;
          }
          input.value = "" + (idx + 1);
          return input;
        }
      })
      .forEach((input) => {
        input.dataset.sortableChanged =
          input.value != input.dataset.sortableOriginalValue;
      });
  };

  private onBeforeCache = () => this.destroy();

  private get root() {
    return this.hasRootTarget ? this.rootTarget : (this.element as HTMLElement);
  }
}
