import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect(): void {
    this.element.addEventListener("keydown", this.disableSubmitOnEnter);
  }

  disconnect(): void {
    this.element.removeEventListener("keydown", this.disableSubmitOnEnter);
  }

  private disableSubmitOnEnter = (e: KeyboardEvent) => {
    if (e.defaultPrevented) {
      return;
    }

    const { key, target, metaKey } = e;

    if (
      key === "Enter" &&
      !metaKey &&
      target instanceof HTMLInputElement &&
      target.type !== "submit"
    ) {
      e.preventDefault();
    }
  };
}
