import { Controller } from "@hotwired/stimulus";

import debounce from "lodash/debounce";

/**
 * フォームを自動的にサブミットする
 */
export default class extends Controller {
  static values = {
    delay: {
      type: Number,
      default: 300,
    },
  };

  declare delayValue: number;

  private onInput: () => void;

  delayValueChanged(newValue: number) {
    if (this.onInput) {
      this.element.removeEventListener("input", this.onInput);
    }

    this.onInput = debounce(() => this.submit(), newValue);

    this.element.addEventListener("input", this.onInput);
  }

  disconnect(): void {
    this.element.removeEventListener("input", this.onInput);
  }

  submit() {
    const form = this.form;
    if (!form) {
      return;
    }

    const submitter = form.querySelector(
      "input[type='submit'], button:not([type='button'])"
    );
    if (submitter instanceof HTMLElement) {
      form.requestSubmit(submitter);
    } else {
      form.requestSubmit();
    }
  }

  get form(): HTMLFormElement {
    return this.element instanceof HTMLFormElement
      ? this.element
      : (this.element.querySelector("form") as HTMLFormElement);
  }
}
