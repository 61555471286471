import { Controller } from "@hotwired/stimulus";

export default class extends Controller<HTMLElement> {
  static values = {
    required: Boolean,
  };

  declare requiredValue: boolean;

  connect() {
    console.info(this);
    if (this.requiredValue) {
      this.update();

      this.element.addEventListener("change", this.update);
    }
  }

  disconnect() {
    this.element.removeEventListener("change", this.update);
  }

  checkAll() {
    this.checkboxes.forEach(setChecked(true));
  }

  uncheckAll() {
    this.checkboxes.forEach(setChecked(false));
  }

  get checkboxes() {
    return this.element.querySelectorAll<HTMLInputElement>(
      "input[type='checkbox']"
    );
  }

  private update = () => {
    const checked = this.element.querySelector<HTMLInputElement>(
      "input[type='checkbox']:checked"
    );
    const checkboxes = this.checkboxes;

    const required = !checked || checked.disabled;
    checkboxes.forEach(setRequired(required));
  };
}

function setRequired(required: boolean) {
  return (input: HTMLInputElement) => {
    input.required = required;
    input.ariaRequired = required ? "true" : "false";
  };
}

function setChecked(checked: boolean) {
  return (input: HTMLInputElement) => {
    if (input.checked != checked) {
      input.checked = checked;
      input.dispatchEvent(new Event("change", { bubbles: true }));
    }
  };
}
