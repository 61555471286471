import { Controller } from "@hotwired/stimulus";

import { listen } from "dom-helpers";

/**
 * target-value:
 *   監視するチェックボックスのセレクタ
 *
 * test-value:
 *   target の値が test と同じとき enable
 *
 * array-value:
 *   test-value が配列なら true を指定する
 *
 * disable-value:
 *   true なら target.value == test のとき disabled
 *   false なら target.value != test のとき disabled
 *
 * no-disabled-value:
 *   true なら disabled を設定しない
 */
export default abstract class<T extends HTMLElement> extends Controller {
  static values = {
    target: String,
    test: String,
    array: Boolean,
    disable: Boolean,
    noDisabled: {
      type: Boolean,
      default: false,
    },
  };

  static classes = ["on", "off"];

  declare targetValue: string;
  declare testValue: string;
  declare arrayValue: boolean;
  declare disableValue: boolean;
  declare noDisabledValue: boolean;
  declare onClasses: string[];
  declare offClasses: string[];

  protected unsubscribe: null | (() => void) = null;

  protected on() {
    updateClassList(
      this.element,
      [...this.onClasses, ...this.offClasses],
      this.disableValue ? this.offClasses : this.onClasses
    );

    this.disable(this.disableValue);
  }

  protected off(): void {
    updateClassList(
      this.element,
      [...this.onClasses, ...this.offClasses],
      !this.disableValue ? this.offClasses : this.onClasses
    );

    this.disable(!this.disableValue);
  }

  protected disable(disable: boolean) {
    // noDisabledValue のとき何もしない
    if (this.noDisabledValue) return;

    if (disable) {
      this.element.setAttribute("disabled", "disabled");
      if ("selected" in this.element) {
        this.element["selected"] = false;
      }
    } else {
      this.element.removeAttribute("disabled");
    }
  }

  connect(): void {
    const target = this.target;

    if (target) {
      const listener = () => {
        const value = this.selectedValue;
        // console.info(this.element, value);
        if (value !== null && this.test.includes(value)) {
          this.on();
        } else {
          this.off();
        }
      };

      this.unsubscribe = listen(target, "change", listener);
      listener.call(null);
    }
  }

  disconnect(): void {
    this.unsubscribe?.call(null);
    this.unsubscribe = null;
  }

  abstract get target(): T | null;
  abstract get selectedValue(): string | null;

  protected get test() {
    let test: string[] = [];
    if (this.arrayValue) {
      test = JSON.parse(this.testValue);
    } else {
      test.push(this.testValue);
    }
    return test;
  }
}

function updateClassList(element: Element, remove: string[], add: string[]) {
  const classList = document.createElement("span").classList;
  classList.value = element.classList.value;

  classList.remove(...remove);
  add.forEach((_) => classList.add(_));

  element.classList.value = classList.value;
}
