import "@hotwired/turbo-rails";

import { onBeforeCache } from "@/vendor/turbo";

// tooltip, popover, modal をキャッシュしない
onBeforeCache(() => {
  document
    .querySelectorAll<HTMLElement>(
      ".tooltip, .popover, .modal.show, .modal.in, .modal-backdrop"
    )
    .forEach((e) => {
      e.dataset.turboTemporary = "";
    });
  // modal は閉じていることにする
  document.body.classList.remove("modal-open");
});
