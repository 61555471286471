import InputSpyController from "./input_spy_controller";

/**
 * target-value:
 *   監視するチェックボックスのセレクタ
 *
 * test-value:
 *   target の値が test と同じとき enable
 *
 * array-value:
 *   test-value が配列なら true を指定する
 *
 * disable-value:
 *   true なら target.value == test のとき disabled
 *   false なら target.value != test のとき disabled
 */
export default class extends InputSpyController<HTMLSelectElement> {
  get target() {
    return document.querySelector<HTMLSelectElement>(this.targetValue);
  }

  get selectedValue() {
    const target = this.target;
    if (target) {
      return target.value;
    }
    return null;
  }
}
