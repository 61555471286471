import { Controller } from "@hotwired/stimulus";

import { listen } from "dom-helpers";

class ControllerBase extends Controller {
  inputValue: string;
  disableValue: boolean;
  onClasses: string[];
  offClasses: string[];
}

/**
 * input-value:
 *   監視するチェックボックスのセレクタ
 *
 * disable-value:
 *   true ならチェックボックスが off のとき disabled
 */
export default class extends (Controller as typeof ControllerBase) {
  static values = {
    input: String,
    disable: Boolean,
  };

  static classes = ["on", "off"];

  private unsubscribe: () => void;

  private on() {
    this.onClasses.forEach((onClass) => {
      this.element.classList.add(onClass);
    });
    this.offClasses.forEach((offClass) => {
      this.element.classList.remove(offClass);
    });
    if (this.disableValue) {
      this.disable(false);
    }
  }

  private off(): void {
    this.onClasses.forEach((onClass) => {
      this.element.classList.remove(onClass);
    });
    this.offClasses.forEach((offClass) => {
      this.element.classList.add(offClass);
    });
    if (this.disableValue) {
      this.disable(true);
    }
  }

  private disable(disable: boolean) {
    if (disable) {
      this.element.setAttribute("disabled", "disabled");
    } else {
      this.element.removeAttribute("disabled");
    }
  }

  connect(): void {
    const input = document.querySelector(this.inputValue) as HTMLInputElement;
    const listener = () => {
      if (input.checked) {
        this.on();
      } else {
        this.off();
      }
    };

    this.unsubscribe = listen(input, "change", listener);
    listener.call(null);
  }

  disconnect(): void {
    this.unsubscribe?.call(null);
    this.unsubscribe = null;
  }
}
