import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  open({
    params: {
      url,
      target,
      width = window.outerWidth,
      height = window.outerHeight,
      features = "",
    },
  }) {
    const left = (window.outerWidth - width) / 2 + window.screenLeft;
    const top = (window.outerHeight - height) / 2 + window.screenTop;

    window.open(
      url,
      target,
      `left=${left},top=${top},width=${width},height=${height},${features}`
    );
  }

  close() {
    window.close();
  }
}
