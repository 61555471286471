import { Controller } from "@hotwired/stimulus";

import "./input_component.scss";

export default class extends Controller<HTMLElement> {
  static targets = ["input"];

  declare inputTarget: HTMLInputElement;

  toggle() {
    if (this.inputTarget.type == "text") {
      this.inputTarget.type = "password";
    } else {
      this.inputTarget.type = "text";
    }
  }
}
